$(function() {
    $('.hamburger').click(function(ev) {
        $('.mobile-nav-items').toggleClass('mobile-nav-items--open');
        $('.hamburger').toggleClass('hamburger--close');
        $('body').toggleClass('menu-open');
        ev.preventDefault();
        ev.stopPropagation();
    });

    $('.js-accordian-button').click(function() {
        var panel = $(this).siblings(".accordian");
        panel.toggleClass("expanded");
        if (panel.hasClass("accordian--opened")) {
            panel.css("max-height", (panel[0].scrollHeight + 50) + "px");
            panel.toggleClass("accordian--opened");
            setTimeout(function() {
                panel.css("max-height", "");
            }, 100);

        } else {
            panel.css("max-height", (panel[0].scrollHeight + 50) + "px");
            panel.toggleClass("accordian--opened");
            setTimeout(function() {
                panel.css("max-height", "");
            }, 500);

        }
    });
});